import React, { useState } from "react";
import TextInput from "../TextInput";
import Select from "react-select";
import FromProgress from "./FormProgress";
import FromFooter from "./FormFooter";
import { Slide } from "react-reveal";
import Button from "../Button";
import TableComponent from "../DataTable";
import { TiTick } from "react-icons/ti";
import { AiOutlineClear } from "react-icons/ai";
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  mainHeader: {
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  image: {
    width: 70, // Set the width of the image
    height: 130, // Set the height of the image
    // ... other styles like margin or border
  },
  headerText: {
    color: '#000',
    fontSize: 20,
    fontWeight: '600',
    marginLeft: 100
  },
  headerText3: {
    color: '#000',
    fontSize: 16,
    fontWeight: '600',
    paddingBottom: 10,
    textAlign: "center",
  },
  headerText2: {
    color: '#000',
    fontSize: 20,
    fontWeight: '600',
    textAlign: "center",
  },
  page: {
    flexDirection: 'column',
    width: '210mm',  // A4 width
    minHeight: '297mm', // A4 height
    padding: '10mm', // Adjust padding as needed
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
  },
  row: {
    fontSize: 8,
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    borderColor: '#000',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  headerCell: {
    textAlign: 'center',
    padding: 3,
    color: '#000',
    fontSize: 8,
  },
  cell: {
    fontSize: 8,
    borderStyle: 'solid',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: '#000',
    padding: 5,

  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    paddingHorizontal: 20
  },
  headerContainer: {
    backgroundColor: '#bd1e25',
    padding: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 18
  },
  headerTextMain: {
    fontSize: 20,
    color: '#fff',
    fontWeight: '600',
  },
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rightInvoice: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  invoiceText: {
    fontSize: 8,
  }
});
const MemberShipForm = ({
  formData,
  setFormData,
  handleChange,
  departmentList,
  isProgress,
  handleSubmit,
  totalSteps,
  handleBack,
  handleNext,
  data,
  loading,
  selectedRows,
  filters,
  columns,
  filterTypes,
  handleFilterChange,
  addFilter,
  removeFilter,
  applyFilters,
  handleClear,
  handleSelectRow,
  column,
  handlePayment,
  agreeTerms,
  setAgreeTerms,
  summaryCol,
  userData,
  isGroup,
  setIsGroup,
  paymentStatus,
  validateChecker,
  department,
  setDepartment
}) => {

  const [billToDepartment, setBillToDepartment] = useState(false)
  const totalAmount = selectedRows?.selectedRows.reduce((acc, item) => {
    return acc + parseFloat(item?.amount);
  }, 0)
  const customClass = "flex gap-5 flex-wrap sm:flex-nowrap ";

  const defaultDepartment = departmentList.find(dept => dept.id === formData.department);
  const defaultValue = {
    value: defaultDepartment?.id,
    label: defaultDepartment?.name
  };
  const calculateTotal = (data) => {
    return data.reduce((acc, curr) => acc + parseFloat(curr?.amount?.replace('$', '')), 0);
  };
  const TableHeader = () => (
    <View style={[styles.header]}>
      <Text style={[styles.headerCell, { flexBasis: '15%' }]}>QUANTITY</Text>
      <Text style={[styles.headerCell, { flexBasis: '55%' }]}>DESCRIPTION</Text>
      <Text style={[styles.headerCell, { flexBasis: '15%' }]}>UNIT PRICE</Text>
      <Text style={[styles.headerCell, { flexBasis: '15%' }]}>AMOUNT</Text>
    </View>
  )
  const TableRow = ({ name, amount, lastIndex, department }) => (
    <View style={[styles.row, lastIndex && { borderBottomWidth: 0.5 }]}>
      <Text style={[styles.cell, { flexBasis: '15%', textAlign: "center" }]}>1</Text>
      <Text style={[styles.cell, { flexBasis: '55%', }]}>{'Membership - ' + name + " " + `(${department})`}</Text>
      <Text style={[styles.cell, { flexBasis: '15%', textAlign: 'right', }]}>{amount}</Text>
      <Text style={[styles.cell, { flexBasis: '15%', textAlign: 'right', }]}>{'$' + amount}</Text>
    </View>
  );
  const TotalAmount = ({ data, date }) => {
    const total = calculateTotal(data);
    return (
      <View style={styles.row}>
        <Text style={[styles.cell, { borderWidth: 1, borderColor: '#000', flexBasis: '70%' }]}>Covering Period from January 1, {date} to December 31, {date}</Text>
        <Text style={[styles.cell, { borderWidth: 1, borderColor: '#000', flexBasis: '15%', textAlign: 'center', fontWeight: 600, color: '#000' }]}>SUBTOTAL</Text>
        <Text style={[styles.cell, { borderWidth: 1, borderColor: '#000', flexBasis: '15%', textAlign: 'right', fontWeight: 600, color: '#000' }]}>{total.toFixed(2)}</Text>
      </View>
    );
  };
  const TotalAmount2 = ({ data }) => {
    const total = calculateTotal(data);

    return (
      <View style={styles.row}>
        <Text style={{ flexBasis: '85%' }}></Text>
        <View style={[styles.cell, { borderWidth: 1, gap: 5, borderColor: '#000', flexBasis: '15%', fontWeight: 600, color: '#000' }]}>
          <Text style={{ textAlign: 'right' }}>${total.toFixed(2)}</Text>
          <View>
            <Text style={{ textAlign: 'center', fontSize: 8, color: 'gray' }}>PAY THIS</Text>
          </View>
          <View>
            <Text style={{ textAlign: 'center', fontSize: 8, color: 'gray' }}>AMOUNT</Text>
          </View>
        </View>
      </View>
    );
  };

  const Footer = ({ pageNumber, totalPages }) => {
    if (pageNumber === totalPages) {
      return (
        <>
          <View style={{ gap: 3, marginBottom: 50, marginLeft: 30 }}>
            <Text style={[styles.invoiceText, { fontSize: 10 }]}>DIRECT ALL INQUIRES TO:</Text>
            <Text style={styles.invoiceText}>Richard Watkins</Text>
            <Text style={styles.invoiceText}>(601) 981 - 2915</Text>
            <Text style={styles.invoiceText}>email: admin@msfirechiefs.org</Text>
            <Text style={styles.invoiceText}>Subject reference: MFCA Conference</Text>
          </View>

          <View style={{ gap: 3, marginBottom: 50, marginRight: 30 }}>
            <Text style={[styles.invoiceText, { fontSize: 10 }]}>MAKE ALL CHECKS PAYABLE TO:</Text>
            <Text style={styles.invoiceText}>Mississippi Fire Chiefs Association</Text>
            <Text style={styles.invoiceText}>P.O Box 5231</Text>
            <Text style={styles.invoiceText}>Jackson, MS 39296-5231</Text>
          </View>

        </>
      )
    }
  }
  const MyDocument = ({ data, dueDate }) => {
    console.log('dueDate: ', dueDate);
    // here 
    const date = new Date(data[0]?.date);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const firstPageItems = 30;
    const otherPageItems = 40;
    const totalItems = data.length;
    const remainingItems = totalItems - firstPageItems;
    const numOtherPages = Math.ceil(remainingItems / otherPageItems);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Text style={styles.headerTextMain}>Mississippi Fire Chiefs Association</Text>
            <Text style={styles.headerTextMain}>INVOICE</Text>
          </View>
          <View style={{ width: 200, paddingTop: 10, textAlign: 'center', marginLeft: '50', fontSize: 10 }}>
            <Text>P.O. Box 5231</Text>
            <Text>Jackson , MS 39296-5231</Text>
          </View>
          <View style={styles.detailsContainer}>
            <View style={{ gap: 3, marginTop: 30, marginBottom: 30 }}>
              <Text style={[styles.invoiceText]}>BILLED TO:</Text>
              {billToDepartment &&
                <Text style={styles.invoiceText}>{department?.name} </Text>
              }
              <Text style={styles.invoiceText}>{billToDepartment ? department.billing_address === '' ? `` : department.billing_address + ", " + department?.address_2 : formData.address} </Text>
              <Text style={styles.invoiceText}>{billToDepartment ? department.city !== '' ? department.city : `` : formData.city}{billToDepartment ? department.state !== '' ? "  " + department.state : ` ` : formData.state}{billToDepartment ? department.zip_code !== '' ? " " + department.zip_code : ` ` : ' ' + formData.zip_code} </Text>
            </View>
            <View>
              <Text style={{ fontSize: 12 }}>
                INVOICE STATUS: <Text style={{ color: '#bd1e25' }}>UNPAID</Text>
              </Text>
            </View>
            <View style={styles.rightInvoice}>
              <View style={{ borderRightWidth: 1, paddingRight: 5, marginRight: 5, gap: 3 }}>
                <Text style={[styles.invoiceText, { marginLeft: 'auto', fontSize: 8 }]}>INVOICE NUMBER</Text>
                <Text style={[styles.invoiceText, { marginLeft: 'auto', fontSize: 8 }]}>INVOICE DATE</Text>
                <Text style={[styles.invoiceText, { marginLeft: 'auto', fontSize: 8 }]}>DUE Date</Text>
              </View>
              <View style={{ gap: 3 }}>
                <Text style={[styles.invoiceText, { fontSize: 8 }]}>{selectedRows?.invoice_number}</Text>
                <Text style={[styles.invoiceText, { fontSize: 8 }]}>{formattedDate}</Text>
                <Text style={[styles.invoiceText, { fontSize: 8 }]}>{dueDate?.dueDateString}</Text>
              </View>
            </View>
          </View>
          <TableHeader />
          {data.slice(0, firstPageItems).map((item, index, array) => (

            <>
              <TableRow key={index} name={item.name} department={item?.department} amount={item.amount} lastIndex={index === array.length - 1} />
            </>
          ))}
          {data.length < firstPageItems && (
            <>
              <TotalAmount date={dueDate.due_date_year} data={data} />
              <TotalAmount2 data={data} />
              <View
                fixed
                style={[styles.detailsContainer, styles.footer]}
                render={({ pageNumber, totalPages }) => (
                  <Footer {...{ pageNumber, totalPages }} />
                )}
              />
            </>
          )}
        </Page>
        {Array.from({ length: numOtherPages }, (_, index) => (
          <Page key={index + 1} size="A4" style={styles.page} render={({ pageNumber, totalPages }) => (
            <>
              <TableHeader />
              {data
                .slice(firstPageItems + index * otherPageItems, firstPageItems + (index + 1) * otherPageItems)
                .map((item, idx, array) => {
                  console.log('itemss: ', item);
                  return (
                    <TableRow key={idx} department={item?.department} name={item.name} amount={item.amount} lastIndex={idx === array.length - 1} />
                  )
                })}

              {pageNumber === totalPages &&
                <>
                  <TotalAmount date={dueDate.due_date_year} data={data} />
                  <TotalAmount2 data={data} />
                </>
              }
              <View
                fixed
                style={[styles.detailsContainer, styles.footer]}
                render={({ pageNumber, totalPages }) => (
                  <Footer {...{ pageNumber, totalPages }} />
                )}
              />
            </>
          )}>
          </Page>
        ))}
      </Document>
    )
  }


  return (
    <div className="w-full boxBg rounded-2xl p-5 md:p-10 my-5 md:my-10   ">
      <div className="flex items-center  justify-between flex-auto ">
        <div className="">
          <img src="/Logo.png" alt='logo' className="w-32 object-fill" />
        </div>
        <div className="md:pr-32">
          <div className="text-center">
            <div className="text-center text-md font-bold py-1 text-primary">
              Mississippi Fire Chiefs Association
            </div>
            <div className="text-xs font-semibold">P.O. Box 5231</div>
            <div className="text-xs font-semibold">Jackson, MS 39296</div>
            <div className="text-xs font-semibold">(601) 981 - 2915</div>
          </div>
          <div className="hidden lg:block lg:w-fit mx-auto mt-10">
            <FromProgress {...{ isProgress }} />
          </div>
        </div>
        <div />
      </div>

      <div className={customClass + " w-full flex-col pt-10"}>
        {isProgress === 1 ?
          <Slide bottom>
            <div className={customClass + " w-full flex-col pt-10"}>
              <div className={customClass}>
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.name}
                  name={"name"}
                  label={"Name"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.address}
                  name="address"
                  label={"Address"}
                />
              </div>
              <div className={customClass}>
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.city}
                  name={"city"}
                  label={"City"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.state}
                  name="state"
                  label={"State"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.county}
                  name={"county"}
                  label={"County"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.zip_code}
                  name={"zip_code"}
                  label={"zip"}
                />
              </div>
              <div className={customClass}>
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.email}
                  name={"email"}
                  disabled
                  label={"Email"}
                />
              </div>
              <div className={customClass}>
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.home_phone}
                  name={"home_phone"}
                  label={"Home Phone"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  value={formData.business_phone}
                  name={"business_phone"}
                  label={"Business Phone"}
                />
              </div>
              <div className={customClass}>
                <div className="w-full">
                  <label htmlFor="" className={`text-xs font-semibold text-left`}>
                    Department or Organization
                  </label>
                  <Select
                    options={departmentList.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    value={defaultValue}
                    onChange={(e) =>
                      setFormData({ ...formData, department: e.value })
                    }
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        fontSize: "12px",
                        ":focus": {
                          borderColor: "black",
                        },
                        backgroundColor: state.isFocused
                          ? "transparent"
                          : "transparent",
                        indicatorsContainer: (prevStyle, state) =>
                          state.isMulti
                            ? {
                              ...prevStyle,
                            }
                            : null,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000000",
                        fontSize: "12px",
                      }),
                    }}
                    placeholder="Select Department or Organization"
                  />
                </div>

                <div className="w-full">

                  {/*Commented membership dropdown out as this should be controlled internally ACG*/}

                  {/*<label htmlFor="" className={`text-xs font-semibold text-left`}>
                    Membership class 
                  </label> */}

                  {/* <Select
                    options={MemberDropdown}
                    value={defaultClass}
                    onChange={(e) =>
                      setFormData({ ...formData, membership_class: e.value })
                    }
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        fontSize: "12px",
                        ":focus": {
                          borderColor: "black",
                        },
                        backgroundColor: state.isFocused
                          ? "transparent"
                          : "transparent",
                        indicatorsContainer: (prevStyle, state) =>
                          state.isMulti
                            ? {
                              ...prevStyle,
                            }
                            : null,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000000",
                        fontSize: "12px",
                      }),
                    }}
                    placeholder="Select Department or Organization"
                  /> */}
                </div>
              </div>

              <div className={customClass}>
                <TextInput
                  onChange={(e) => handleChange(e)}
                  name={"dept_time"}
                  label={"How long with Dept. or Org"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  name={"title"}
                  value={formData.title}
                  label={"Rank/Title"}
                />
                <TextInput
                  onChange={(e) => handleChange(e)}
                  type={"date"}
                  value={formData.date_paid}
                  name={"date_paid"}
                  label={"Date"}
                />
              </div>
              {!paymentStatus &&
                <div className="flex items-center gap-2 ">
                  <input type="checkbox" id="terms" defaultChecked={true} className="cursor-not-allowed" name="terms" />
                  <label htmlFor="terms" className="text-xs text-gray-600 font-semibold cursor-pointer"> {userData.is_member ? `Renew my membership (
                  ${formData.membership_class === "life_member" ? "0$" : "$45"})` : `  Signup for a membership (
                    ${formData.membership_class === "life_member" ? "0$" : "$45"})`}</label>
                </div>
              }
              <div className="flex items-center gap-2 ">
                <input type="checkbox" id="group" checked={isGroup} onChange={(e) => !paymentStatus && setIsGroup(e.target.checked)} className="" name="group" />
                <label htmlFor="group" className="text-xs text-gray-600 font-semibold cursor-pointer select-none">Group Payment</label>
              </div>

            </div>
          </Slide>
          : isProgress === 2 ?
            <div className="px-5 mt-5">
              <>
                <div className="text-3xl my-6 md:my-6 text-center mx-auto w-fit text-primary font-medium">
                  Member Data for Group Payment
                </div>
                <div className="md:w-1/2 mb-5 flex flex-col mx-auto border p-5 rounded-lg shadow-xl">
                  {filters.map((filter, index) => (
                    <>

                      <div className="flex flex-col gap-5 border px-5 py-3 rounded-lg">
                        <div key={index} className="flex gap-5 w-full">
                          <div className="w-full">
                            <Select
                              options={columns}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  fontSize: "12px",
                                  ":focus": {
                                    borderColor: "black",
                                  },
                                  backgroundColor: state.isFocused
                                    ? "transparent"
                                    : "transparent",
                                  indicatorsContainer: (prevStyle, state) =>
                                    state.isMulti
                                      ? {
                                        ...prevStyle,
                                      }
                                      : null,
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: "#000000",
                                  fontSize: "12px",
                                }),
                              }}
                              value={
                                columns.find(
                                  (option) => option.value === filters[index].column
                                ) || null
                              }
                              onChange={(selectedOption) =>
                                handleFilterChange(index, "column", selectedOption.value, selectedOption.type)
                              }
                              placeholder="Select Column"
                            />
                          </div>
                          {filter.column !== "date_paid" && filter.filterType !== "boolean" ? (
                            <div className="w-full">
                              <Select
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    fontSize: "12px",
                                    ":focus": {
                                      borderColor: "black",
                                    },
                                    backgroundColor: state.isFocused
                                      ? "transparent"
                                      : "transparent",
                                    indicatorsContainer: (prevStyle, state) =>
                                      state.isMulti
                                        ? {
                                          ...prevStyle,
                                        }
                                        : null,
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: "#000000",
                                    fontSize: "12px",
                                  }),
                                }}
                                options={filterTypes}
                                value={filterTypes.find(
                                  (option) => option.value === filters[index].type
                                )}
                                onChange={(selectedOption) =>
                                  handleFilterChange(index, "type", selectedOption.value)
                                }
                                placeholder="Filter Type"
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="flex gap-5 items-center ">
                          {filter?.column === "date_paid" ? (
                            <div className="flex gap-5 w-full">
                              <TextInput
                                label={"Start date"}
                                type={"date"}
                                onChange={(e) =>
                                  handleFilterChange(index, "start_date", e.target.value)
                                }
                              />
                              <TextInput
                                label={"End date"}
                                type={"date"}
                                onChange={(e) =>
                                  handleFilterChange(index, "end_date", e.target.value)
                                }
                              />
                            </div>
                          ) : filter.filterType === "boolean" ?
                            <select className="border border-gray-300 rounded-md text-xs px-2 h-[38px] outline-blue-400 w-full" id="cars" onChange={(e) => handleFilterChange(index, "query", e.target.value, 'boolean')} >
                              <option value="">select true or false</option>
                              <option value="1">True</option>
                              <option value="0">False</option>
                            </select> : (
                              <>
                                <TextInput
                                  type="text"
                                  placeholder={`Enter your query..`}
                                  value={filter.query}
                                  onChange={(e) =>
                                    handleFilterChange(index, "query", e.target.value)
                                  }
                                />
                              </>
                            )}
                          <div className="flex items-center">
                            <div
                              onClick={() => handleClear(index)}
                              className="rounded-full cursor-pointer h-[35px] border shadow-sm w-[35px] p-1 flex flex-col items-center justify-center"
                            >
                              <AiOutlineClear className=" h-4 w-4 " />
                            </div>
                            {filters.length > 1 && (
                              <svg
                                onClick={() => removeFilter(index)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="45"
                                height="45"
                                viewBox="0 0 40 40"
                                fill="none"
                              >
                                <g filter="url(#filter0_d_44_1073)">
                                  <rect
                                    x="6"
                                    y="6"
                                    width="28"
                                    height="28"
                                    rx="14"
                                    fill="white"
                                  />
                                  <path
                                    d="M15.0992 25.6C15.0992 25.9713 15.2467 26.3274 15.5093 26.5899C15.7718 26.8525 16.1279 27 16.4992 27H23.4992C23.8705 27 24.2266 26.8525 24.4892 26.5899C24.7517 26.3274 24.8992 25.9713 24.8992 25.6V17.2H26.2992V15.8H23.4992V14.4C23.4992 14.0287 23.3517 13.6726 23.0892 13.4101C22.8266 13.1475 22.4705 13 22.0992 13H17.8992C17.5279 13 17.1718 13.1475 16.9093 13.4101C16.6467 13.6726 16.4992 14.0287 16.4992 14.4V15.8H13.6992V17.2H15.0992V25.6ZM17.8992 14.4H22.0992V15.8H17.8992V14.4ZM17.1992 17.2H23.4992V25.6H16.4992V17.2H17.1992Z"
                                    fill="#CF5D5E"
                                  />
                                  <path
                                    d="M17.9004 18.6H19.3004V24.2H17.9004V18.6ZM20.7004 18.6H22.1004V24.2H20.7004V18.6Z"
                                    fill="#CF5D5E"
                                  />
                                </g>
                                <defs>
                                  <filter
                                    id="filter0_d_44_1073"
                                    x="0"
                                    y="0"
                                    width="40"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                  >
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_44_1073"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_44_1073"
                                      result="shape"
                                    />
                                  </filter>
                                </defs>
                              </svg>
                            )}
                          </div>

                        </div>
                      </div>
                      <div className="my-0 text-red-500 text-xs font-semibold pt-1">*Search is case sensitive </div>
                    </>
                  ))}
                  <div className="mt-5 flex gap-5 justify-end">
                    <Button
                      customClass={"!text-xs "}
                      onClick={addFilter}
                      text={"+ Add Filter"}
                    />
                    <Button customClass={"!text-xs !gap-0"} onClick={applyFilters}>
                      <TiTick /> Apply Filter
                    </Button>
                  </div>
                </div>
                <div className="">
                  <div className="w-fit mx-auto pb-10">
                    {data && <TableComponent selectableRows onSelectedRowsChange={handleSelectRow} progressPending={loading} {...{ data, column }} />}
                  </div>
                </div>
              </>
            </div>
            :
            <div className="">
              <div className="text-3xl my-6 md:my-6 text-center mx-auto w-fit text-primary font-medium">

                Payment Information
              </div>
              <div className="w-fit mx-auto pb-10 pt-10">
                <PDFDownloadLink document={<MyDocument dueDate={selectedRows} data={selectedRows?.selectedRows} />} onClick={() => validateChecker()} className='text-xs p-2 mb-3 px-4 rounded-md flex w-fit justify-end bg-primary ml-auto text-white' fileName="Invoice.pdf">
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Export PDF'
                  }
                </PDFDownloadLink>
                {selectedRows?.selectedRows &&
                  <TableComponent data={selectedRows?.selectedRows} column={summaryCol} />
                }
                <div className="font-bold justify-between flex  gap-20  ml-auto text-white p-3 bg-primary ">
                  <div>Total</div>
                  <div>${totalAmount ? totalAmount : 'Loading...'}
                  </div>
                </div>
                <div className="border rounded-b-lg border-gray-300 px-3 pb-3">
                  <div className="flex items-center py-1 mt-1 gap-2 w-1/ ml-auto ">
                    <input type="checkbox" id="terms" checked={!billToDepartment} onChange={(e) => setBillToDepartment(!e.target.checked)} className="cursor-pointer" />
                    <label htmlFor="terms" className="text-xs text-gray-600 font-semibold cursor-pointer"> Bill to member's address</label>
                  </div>
                  <div className="flex items-center py-1  gap-2 w-1/ ml-auto ">
                    <input type="checkbox" id="terms" checked={billToDepartment} onChange={(e) => setBillToDepartment(e.target.checked)} className="cursor-pointer" />
                    <label htmlFor="terms" className="text-xs text-gray-600 font-semibold cursor-pointer"> Bill to department</label>
                  </div>
                  {billToDepartment &&
                    <div className=" items-center gap-2 w-fi pt-2">
                      <div className="flex items-center gap-2 w-full pt-2">
                        <TextInput value={department.billing_address} onChange={(e) => setDepartment({ ...department, billing_address: e.target.value })} inputStyle={" !w-full !text-black text-basee"} placeholder={'Enter billing address'} />
                        <TextInput value={department.address_2} onChange={(e) => setDepartment({ ...department, address_2: e.target.value })} inputStyle={" !w-full !text-black text-basee"} placeholder={'Enter Address 2'} />
                      </div>
                      <div className="flex items-center gap-2 w-fit pt-2">


                        <TextInput value={department.city} onChange={(e) => setDepartment({ ...department, city: e.target.value })} inputStyle={" !w-[200px] !text-black text-basee"} placeholder={'Enter City'} />
                        <TextInput value={department.state} onChange={(e) => setDepartment({ ...department, state: e.target.value })} inputStyle={" !w-[50px] !text-black text-basee"} placeholder={'Enter State'} />
                        <TextInput value={department.zip_code} onChange={(e) => setDepartment({ ...department, zip_code: e.target.value })} inputStyle={" !w-[160px] !text-black text-basee"} placeholder={'Enter Postal Code'} />
                        <div>
                        </div>
                      </div>
                    </div>
                  }

                </div>

                <div className="flex items-center py-1 mt-3 gap-2 w-1/ ml-auto">
                  <input type="checkbox" id="terms" checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} className="cursor-pointer" name="terms" value="Bike" />
                  <label className="text-xs text-gray-600 font-semibold cursor-pointer"> I agree to the terms and services</label>
                </div>
              </div>
            </div>
        }
        <FromFooter
          {...{
            handleNext,
            handleBack,
            isProgress,
            totalSteps,
            handleSubmit,
            handlePayment,
            agreeTerms,
          }}
        />
      </div>
    </div>
  );
};

export default MemberShipForm;
