import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PasswordInput from "../components/PasswordInput";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { regexEmail } from "../../src/utils/helpers";
import { ERROR } from "../../src/utils/contants";
import { LogIn } from "../services/auth-services";
import { jwtDecode } from "jwt-decode";

const SignIn = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({
        email: "",
        username: '',
        password: "",
    });
    function checkValidations() {
        if (!regexEmail(credentials.email)) {
            toast.warn(ERROR.EMAIL_VALIDATION);
            setLoading(false);
            return false;
        } else if (credentials.password === "") {
            toast.warn(ERROR.EMPTY_PASSWORD);
            setLoading(false);
            return false;
        } else if (credentials.password.length < 8) {
            toast.warn(ERROR.PASSWORD_LENGTH);
            setLoading(false);
            return false;
        }
        return true;
    }
    const handleSignIn = () => {
        setLoading(true);
        if (checkValidations()) {
            let body = credentials
            body.username = credentials.email
            LogIn(body)
                .then((res) => {
                    const userData = JSON.stringify(jwtDecode(res.data.access))
                    const token = JSON.stringify(res.data.access)
                    localStorage.setItem("user", userData)
                    localStorage.setItem("token", token)
                    toast.success("Logged In");
                    setLoading(false);
                    window.location.href = "/";
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response) {
                        const errors = error.response.data;
                        Object.keys(errors).forEach((key) => {
                            if (Array.isArray(errors[key])) {
                                errors[key].forEach((message) => {
                                    toast.error(`${message}`);
                                });
                            } else {
                                toast.error(`${errors[key]}`);
                            }
                        });
                    } else {
                        toast.error("Network error.");
                    }
                });
        }
    };
    const onChangeFields = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    return (
        <div className="w-full min-h-screen px-5 flex flex-col justify-center items-center my-auto ">
            <div className="border rounded-2xl  border-none boxBg  bg-white p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">
                <img src="/Logo.png" alt="" className="w-20 mx-auto" />
                <div className="text-xl font-semibold text-black leading-[35px] text-center">
                    Log In
                </div>
                <TextInput
                    name="email"
                    value={credentials.name}
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Enter your email"}
                />
                <PasswordInput
                    name="password"
                    value={credentials.password}
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Enter password"}
                />
                <div
                    onClick={() => navigate("/reset-password")}
                    className="text-primary cursor-pointer text-sm text-right"
                >
                    Forgot password?
                </div>
                <div className=" py-3 px-4  rounded-md">
                    <p className="text-base  font-medium ">You must have an account to renew your membership.
                    </p>
                    <ul className=" text-xs font-medium py-3 text-justify">
                        <li>If you do not have an account, please <Link to={"/signup"}> <span className="underline text-primary cursor-pointer font-medium">click here</span></Link> to create one. If you are having trouble logging in or registering, please email us at info@msfirechiefs.org or give us a call at (601) 981 - 2915</li>
                    </ul>
                </div>
                <Button
                    disabled={loading}
                    text={
                        loading ? (
                            <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
                        ) : (
                            "Continue"
                        )
                    }
                    onClick={handleSignIn}
                    customClass={" mx-auto !py-1 !bg-primary !text-white !font-normal !text-[12px]"}
                />
            </div>
        </div>
    );
};

export default SignIn;
